import React, { useState, useEffect } from 'react';
//import { GoogleLogin, GoogleLogout } from 'react-google-login';
import { gapi } from 'gapi-script';
import Login from './components/Login';
import NewLogin from './components/Login';
import Logout from './components/Logout';
import { Route, Switch } from 'react-router-dom';

import { GlobalProvider } from './context/GlobalState';

import { Home } from './components/Home';
import { AddEmployee } from './components/AddEmployee';
import { EditEmployee } from './components/EditEmployee';



function App() {
  console.log('In app.js signed in = ' + localStorage.getItem('signedIn'));
  if(localStorage.getItem('signedIn')==0){
  console.log('why i am here');
  return (
    <div className="App">
      <div style={{marginLeft:'300px',marginTop:'200px'}}>
      <h1 style={{fontSize:'100px'}}>SEQ Operations</h1>
      <NewLogin />
      </div>
      <br />    
    </div>
  );
  }
else {
  return (
    <GlobalProvider>
      <div className="App">
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/add" component={AddEmployee} exact />
          <Route path="/edit/:entity/:id" component={EditEmployee} exact />
        </Switch>
      </div>
    </GlobalProvider>
  );
}
}

export default App;