import React, { useCallback,useContext, useState, useMemo , useRef,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import JSON from 'JSON';
import axios from 'axios';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './employeeList.css'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import { currencyFormatter,formatNumber } from '../utils/formatter';
import { getPreviousMonthAndYear } from '../utils/dateUtils';
import { exportToCsv } from '../utils/exportToCsv';
import { exportToPdf } from '../utils/exportToPdf';
import { calculateSum } from '../utils/calculateSum';

import { style } from '@mui/system';

export const Reports = (param) => {
  const [gridApi, setGridApi] = useState(null);
  const [rowData, setRowData]=useState(); 
  const [recruitingData,setRecruitingData]=useState();
  const [contractRecruitingData,setContractRecruitingData]=useState();
  const [permRecruitingData,setPermRecruitingData]=useState();
  const [contractAccountManagementData,setContractAccountManagementData]=useState();
  const [permAccountManagementData,setPermAccountManagementData]=useState();
  const [contractSalesData,setContractSalesData]=useState();
  const [permSalesData,setPermSalesData]=useState();

  const [totalGP,setTotalGP]=useState(0.0);
  const [totalBill,setTotalBill]=useState(0.0);
  const [totalHours,setTotalHours]=useState(0);
  const [recruiting_gp,setRecruiting_gp]=useState(0);
  const [recruiting_gp_perm,setRecruiting_gp_perm]=useState(0);
  const [account_management_gp,setAccount_management_gp]=useState(0);
  const [account_management_gp_perm,setAccount_management_gp_perm]=useState(0);
  const [recruiter_commission,setRecruiter_commission]=useState(0);
  const [account_manager_commission,setAccount_manager_commission]=useState(0);
  const [total_commission,setTotal_commission]=useState(0);
  const [sales_gp,setSales_gp]=useState(0);
  const [sales_gp_perm,setSales_gp_perm]=useState(0);
  const [sales_commission,setSales_commission]=useState(0);
  const [showSummary,setShowSummary]=useState(true);
  var access_token = localStorage.getItem('access_token');
  const headers = {
    "Authorization": "Bearer "+ access_token
  };
  var resp, data;
  const gridRefRecruitingContract = useRef();
  const gridRefRecruitingPerm = useRef();
  const gridRefAccountManagementContract = useRef();
  const gridRefAccountManagementPerm = useRef();
  const gridRefSalesContract = useRef();
  const gridRefSalesPerm = useRef();
  const [month,setMonth]=useState(getPreviousMonthAndYear(new Date()).prevMonth+1);
  const [year,setYear] = useState(getPreviousMonthAndYear(new Date()).prevYear);
  const [commissionPerson,setCommissionPerson] = useState(53); // SEQ is 1 
  const [commissionPersonsList,setCommissionPersonsList] = useState([]);
  const [showGrid,setShowGrid] = useState(true);

  
  const onGridReady = (params) => {
    console.log("do I come here on submit?");
    axios.get(process.env.REACT_APP_API_URL+'/commissionPersonsList/',{headers:headers})
      .then(response => {
        resp = JSON.stringify(response.data);
        data = JSON.parse(resp);
        setCommissionPersonsList(data);
      }).catch((error)=>{  
            console.log(error);
      });
    axios.get(process.env.REACT_APP_API_URL+'/perm_placements/'+String(month)+"/"+String(year)+"/"+commissionPerson,
      {headers:headers}).then(response =>
    {resp = JSON.stringify(response.data);
     data = JSON.parse(resp);
     
     var data_details=data.slice(1,data.length);
     let filteredList1 = data_details.filter(function(item) {
        if(item.recruiter  === commissionPerson){
            return item;
        }
    });
     setPermRecruitingData(filteredList1);
     let filteredList2 = data_details.filter(function(item) {
        if(item.account_manager  === commissionPerson){
            return item;
        }
    });
     setPermAccountManagementData(filteredList2);
     let filteredList3 = data_details.filter(function(item) {
        if(item.sales  === commissionPerson){
            return item;
        }
    });
     setPermSalesData(filteredList3);
     
    }).catch(error => {
      console.log(error);
      if(error.response.status === 401){
        localStorage.setItem('signedIn', 0);
        window.location.href = "/";
      }
    });  

    axios.get(process.env.REACT_APP_API_URL+'/commissions/'+String(month)+"/"+String(year)+"/"+commissionPerson,
      {headers:headers}).then(response =>
    {
     resp = JSON.stringify(response.data);
     data = JSON.parse(resp);
        setRecruiting_gp(data[0].recruiting_gp);
        setRecruiting_gp_perm(data[0].recruiting_gp_perm);
        setAccount_management_gp(data[0].account_management_gp);
        setAccount_management_gp_perm(data[0].account_management_gp_perm);
        setRecruiter_commission(data[0].recruiter_commission);
        setAccount_manager_commission(data[0].account_manager_commission);
        setTotal_commission(data[0].total_commission);
        setSales_gp(data[0].sales_gp);
        setSales_gp_perm(data[0].sales_gp_perm);
        setSales_commission(data[0].sales_commission);

     var data_details=data.slice(1,data.length);
     setRowData(data_details);
     setTotalGP(calculateSum(data_details.map(function(item){return item.monthly_gp;})));
     setTotalHours(calculateSum(data_details.map(function(item){return item.total_hours;})));
     setTotalBill(calculateSum(data_details.map(function(item){return item.total_bill;})));
     let filteredList1 = data_details.filter(function(item) {
        if(item.recruiter  === commissionPerson){
                 return item;
        }
     });
     setContractRecruitingData(filteredList1);
     let filteredList2 = data_details.filter(function(item) {
        if(item.account_manager  === commissionPerson){
           
            return item;
        }
     });
     setContractAccountManagementData(filteredList2);
     let filteredList3 = data_details.filter(function(item) {
        if(item.sales === commissionPerson){
            
            return item;
        }
     });
     setContractSalesData(filteredList3);
    
    }
     
  ).catch((error)=>{
    console.log(error);
      if(error.response.status === 401){
        localStorage.setItem('signedIn', 0);
        window.location.href = "/";
      }
  }); 
    
    setGridApi(params.api);
    params.api.setDomLayout('autoHeight');
  };

  
  const months = [{value:0,key:'All Months'},
                  {value:1, key:'January'},
                  {value:2 , key:'February'},
                  {value:3, key:'March'},
                  {key:'April',value:4},{key:'May',value:5},{key:'June',value:6},{key:'July',value:7},
                   {key:'August',value:8},{key:'September',value:9},{key:'October',value:10},
                   {key:'November',value:11},{key:'December',value:12}];
  const years = [
                 {value:2022},
                 {value:2023},
                 {value:2024}];
  function ccySymbol(ccy) {
    switch (ccy) {
        case 'USD': return '$';
      case 'CAD': return 'C$';
      case 'GBP': return '£';
      default: return '';
    }
  }

  const gridColumns = [
    { field: 'short_name',resizable: true,headerName:'Client',sortable: true,filter: true},
    { field: 'name',resizable: true,headerName:'Name',
    valueFormatter: params => params.data.ops_resource_first_name+" "+params.data.ops_resource_last_name,
    sortable: true, filter: true},
      
         { field: 'total_hours',resizable: true,headerName:'Hours',sortable: true,
         valueFormatter: params => Number(params.data.total_hours).toFixed(0)},
         { field: 'hourly_bill_rate',resizable: true,headerName:'BillRate',
         valueFormatter: params => (params.data.bill_ccy==='CAD'?'C':'')+currencyFormatter(params.data.hourly_bill_rate),
         sortable: true},
         { field: 'hourly_pay_rate',resizable: true,headerName:'PayRate',
         valueFormatter: params => ccySymbol(params.data.pay_ccy)+formatNumber(params.data.hourly_pay_rate,2),
         sortable: true},
         { field: 'hourly_pay_rate',resizable: true,headerName:'Loaded PayRate',
         valueFormatter: params => currencyFormatter(params.data.hourly_bill_rate-params.data.spread),
         sortable: true},
      
         {field: 'spread',resizable: true,headerName:'Spread',
         valueFormatter: params => currencyFormatter(params.data.spread)},
         { field: 'monthly_gp',resizable: true,headerName:'GP($)',
         valueFormatter: params => currencyFormatter(params.data.monthly_gp),
         sortable: true},
         
     ];
    const permGridColumns = [
        { field: 'client',resizable: true,headerName:'Client',sortable: true,filter: true},
        { field: 'first_name',resizable: true ,headerName: 'First',sortable: true,filter: true, 
        valueFormatter: params => params.data.first_name+" "+params.data.last_name
         },
       
        { field: 'start_date',resizable: true,headerName:'Start Date',sortable: true,filter: true},
      //  { field: 'exchange_rate',resizable: true,headerName:'XRate',
       // valueFormatter: params => params.data.exchange_rate.toFixed(4),
       // sortable: true},
        { field: 'invoice_amount',resizable: true,headerName:'Fee($)',
        valueFormatter: params => currencyFormatter(params.data.invoice_amount),
        sortable: true},
         { field: 'invoice_ccy',resizable: true,headerName:'Fee Ccy',sortable: true,filter: true},
       // { field: 'recruiter_name',resizable: true,headerName:'Recruiter',sortable: true,filter: true},
       // { field: 'account_manager_name',resizable: true,headerName:'Acct Mgr.',sortable: true,filter: true},
       // { field: 'sales_name',resizable: true,headerName:'Sales',sortable: true,filter: true}
        
    ];
    const [columnDefsRecruitingContract] = useState(gridColumns); 
    const [columnDefsRecruitingPerm] = useState(permGridColumns);
    const [columnDefsAccountManagementContract] = useState(gridColumns);
    const [columnDefsAccountManagementPerm] = useState(permGridColumns);
    const [columnDefsSalesContract] = useState(gridColumns);
    const [columnDefsSalesPerm] = useState(permGridColumns);
     
   const indexColumn = {
    headerName: "#",
    field: "index",
    maxWidth: 120,
    cellRenderer: (params) => {
      // Use the rowIndex property to get the index of the row
      return params.rowIndex+1;
    }
  };
   const recruitingContractGridOptions = {
    
    onCellValueChanged:onCellValueChanged,
    onGridReady:onGridReady,
    columnDefs: columnDefsRecruitingContract,
    defaultColDef: {
      sortable: true,
      resizable: true,
      minWidth: 10,
      flex: 1,
    },
  };
  const recruitingPermGridOptions = {
    
    onCellValueChanged:onCellValueChanged,
    onGridReady:onGridReady,
    columnDefs: columnDefsRecruitingPerm,
    defaultColDef: {
      sortable: true,
      resizable: true,
      minWidth: 10,
      flex: 1,
    },
  };
    const accountManagementContractGridOptions = {
        onCellValueChanged:onCellValueChanged,
    onGridReady:onGridReady,
    columnDefs: columnDefsAccountManagementContract,
    defaultColDef: {
      sortable: true,
      resizable: true,
      minWidth: 10,
      flex: 1,
    },
  };
    const accountManagementPermGridOptions = {
        onCellValueChanged:onCellValueChanged,
    onGridReady:onGridReady,
    columnDefs: columnDefsAccountManagementPerm,
    defaultColDef: {
        sortable: true,
        resizable: true,
        minWidth: 10,
        flex: 1,
    },
    };
    const salesContractGridOptions = {
        onCellValueChanged:onCellValueChanged,
    onGridReady:onGridReady,
    columnDefs: columnDefsSalesContract,
    defaultColDef: {
        sortable: true,
        resizable: true,
        minWidth: 10,
        flex: 1,
    },
    };
    const salesPermGridOptions = {
        onCellValueChanged:onCellValueChanged,
    onGridReady:onGridReady,
    columnDefs: columnDefsSalesPerm,
    defaultColDef: {
        sortable: true,
        resizable: true,
        minWidth: 10,
        flex: 1,
    },
    };

   function onCellValueChanged(event) {
    console.log('data after changes is: ', event.data);

  }
  function reloadProjectsAndHours(){
    var access_token = localStorage.getItem('access_token');
    const headers = {
    "Authorization": "Bearer "+ access_token
    };
    setRowData([]);
    setShowGrid(false);
    var post_data={};
    axios.post(process.env.REACT_APP_API_URL+'/reloadProjectsAndHours',post_data,{headers:headers}).then(response =>{
      console.log(response);
      getCommisionDetails();
    }).catch((error)=>{
      console.log(error);
       if(error.response.status === 401){
          localStorage.setItem('signedIn', 0);
          window.location.href = "/";
        } 
    });

  }
  

  function getCommisionDetails(){
    setRowData([]);
    setShowGrid(false);
    var access_token = localStorage.getItem('access_token');
     const headers = {
     "Authorization": "Bearer "+ access_token
    };
    axios.get(process.env.REACT_APP_API_URL+'/commissions/'+String(month)+"/"+String(year)+"/"+commissionPerson,
      {headers:headers}).then(response =>
    {resp = JSON.stringify(response.data);
     data = JSON.parse(resp);
        setRecruiting_gp(data[0].recruiting_gp);
        setRecruiting_gp_perm(data[0].recruiting_gp_perm);
        setAccount_management_gp(data[0].account_management_gp);
        setAccount_management_gp_perm(data[0].account_management_gp_perm);
        setRecruiter_commission(data[0].recruiter_commission);
        setAccount_manager_commission(data[0].account_manager_commission);
        setTotal_commission(data[0].total_commission);
        setSales_gp(data[0].sales_gp);
        setSales_gp_perm(data[0].sales_gp_perm);
        setSales_commission(data[0].sales_commission);
     var data_details=data.slice(1,data.length);
     setRowData(data_details);
     setShowSummary(true);   
     setTotalGP(calculateSum(data_details.map(function(item){return item.monthly_gp;})));
     setTotalHours(calculateSum(data_details.map(function(item){return item.total_hours;})));
     setTotalBill(calculateSum(data_details.map(function(item){return item.total_bill;})));
     setShowGrid(true);
      
    }).catch(error => {
      console.log(error);
      if(error.response.status === 401){
        localStorage.setItem('signedIn', 0);
        window.location.href = "/";
      }
    });
  }
  function exportData(){
    var yyyymmdd = new Date().toISOString().slice(0, 10);
    var person_name=commissionPersonsList.find(item => item.id === commissionPerson).first_name;

    gridApi.exportDataAsCsv({fileName:person_name+".Commision."+month+"."+year+"."+yyyymmdd+".csv"});
   // exportToCsv("Newcommision."+month+"."+year+"."+yyyymmdd+".csv",rowData);
   //exportToPdf();
  } 
  let summary;
  let account_management_summary_contract;
  let account_management_summary_perm;
  let account_management_summary
  let recruiter_summary_contract;
  let recruiter_summary_perm;
  let recruiter_summary;
  let sales_summary_contract;
  let sales_summary_perm;
  let sales_summary;
  let total_summary;
  if(commissionPerson <=4 ){
    summary=(
       <div>
       <span style={{paddingLeft:'5px'}}>Total Bill(Contract): {currencyFormatter(totalBill)} </span>
       <span style={{paddingLeft:'5px'}}>Total Bill(Perm): {currencyFormatter(recruiting_gp_perm)}</span> 
       <span style={{paddingLeft:'5px'}}>Total Bill: {currencyFormatter(totalBill+recruiting_gp_perm)}</span> 
       <br></br>
       <span style={{paddingLeft:'5px'}}>Total GP(Contract): {currencyFormatter(totalGP)}</span>
       <span style={{paddingLeft:'5px'}}>Total GP(Perm): {currencyFormatter(recruiting_gp_perm)}</span>
       <span style={{paddingLeft:'5px'}}>Total GP: {currencyFormatter(totalGP+recruiting_gp_perm)} </span>
       <br></br>
       <span style={{paddingLeft:'5px'}}>Contract GP(%): {formatNumber(totalGP/totalBill*100,2)}</span>
       <span style={{paddingLeft:'5px'}}>All GP(%): {formatNumber((recruiting_gp_perm+totalGP)/(recruiting_gp_perm+totalBill)*100,2)}</span>
       <span style={{paddingLeft:'5px'}}>Avg. Bill Rate: {currencyFormatter(totalBill/totalHours)}</span>
       </div>
    ) }
    else {
        account_management_summary_contract=(
            
            <div>
           
            <span style={{paddingLeft:'0px'}}>
                <b>Account Manager GP Total (Contract): {currencyFormatter(account_management_gp)}</b>
                </span>
           
            </div>
         );
         account_management_summary_perm=(
            
            <div>
           
            <span style={{paddingLeft:'0px'}}>
                <b>Account Manager GP Total (Perm): {currencyFormatter(account_management_gp_perm)}</b>
                </span>
           
            </div>
         );
         account_management_summary=(
            <div>
            <span style={{paddingLeft:'0px'}} >
                <b>Account Manager GP Total (Perm and Contract): {currencyFormatter(account_management_gp+account_management_gp_perm)}</b>
                <br></br>
                <b>Account Manager Commission: {currencyFormatter(account_manager_commission)}</b>
                </span>            
            </div>

         );
         recruiter_summary_contract=
         (
            
            <div>
            <span style={{paddingLeft:'0px'}} >
                <b>Recruiting GP Total (Contract): {currencyFormatter(recruiting_gp)}</b>
                </span>
            
            
            </div>
         );
         recruiter_summary_perm=
         (
            
            <div>
            <span style={{paddingLeft:'0px'}} >
                <b>Recruiting GP Total (Perm): {currencyFormatter(recruiting_gp_perm)}</b>
                </span>
           
            
            </div>
         );
         recruiter_summary= (
            
            <div>
            <span style={{paddingLeft:'0px'}} >
                <b>Recruiting GP Total (Perm and Contract): {currencyFormatter(recruiting_gp_perm+recruiting_gp)}</b>
                <br></br>
                <b>Recruiting Commission: {currencyFormatter(recruiter_commission)}</b>
                </span>            
            </div>
         );
         sales_summary_contract=(
            
            <div>
            
            <span style={{paddingLeft:'0px'}}>
                <b>Sales GP Total(Contract): {currencyFormatter(sales_gp)}</b>
                </span>
            
            </div>
         );
        sales_summary_perm=(
                <div>
            
            <span style={{paddingLeft:'0px'}}>
                <b>Sales GP Total(Perm): {currencyFormatter(sales_gp_perm)}</b>
                </span>
            
            </div>
         );
        sales_summary=(
                <div>
                <span style={{paddingLeft:'0px'}} >
                    <b>Sales GP Total (Perm and Contract): {currencyFormatter(sales_gp+sales_gp_perm)}</b>
                    <br></br>
                    <b>Sales Commission: {currencyFormatter(sales_commission)}</b>
                    </span>            
                </div>
            )

        total_summary=(
            <div>
            <span style={{paddingLeft:'0px'}} >
                <b>Total Commission: {currencyFormatter(sales_commission+recruiter_commission+account_manager_commission)}</b>
                </span>            
            </div> 
        );

    }
  function renderEmptySummary(){
    return (
      <div>
      </div>
    )   ;
  }
  function renderSummary(summary){
   
    if(showSummary==true){
      return (
        <div>
        {summary}
        </div>
      )
    }
  }
  function renderEmptyAgGrid(onGridReady){
    return (
        <div style={{height: 0}} className="ag-theme-alpine">
           <AgGridReact 
           rowData={[]}
           onGridReady={onGridReady}>

           </AgGridReact>
           </div>
    )

  }
  function renderAGGrid(ref,data,gridOptions,onGridReady){
    
    if (showGrid == true) {
        return (
            <div className="ag-theme-alpine">
       
        <AgGridReact
          ref={ref}
          rowData={data}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
        >
        </AgGridReact>
      </div>
        )
    }
    else {
        return (
        <div style={{height: 800}} className="ag-theme-alpine">    
        <LinearProgress/>
        </div>
        );
    }
}


  const setPrinterFriendly = (api) => {
    const eGridDiv = document.querySelector('#myGrid');
    eGridDiv.style.width = '';
    eGridDiv.style.height = '';
    if(api){
    api.setDomLayout('print');
    }
  };
  
  const setNormal = (api) => {
    const eGridDiv = document.querySelector('#myGrid');
    eGridDiv.style.width = '1400px';
    eGridDiv.style.height = '200px';
    if(api){
        api.setDomLayout('normal');
    }
  };
  const onBtPrint = useCallback(() => {

  const recruitingContractApi= gridRefRecruitingContract.current.api;
  const recruitingPermApi= gridRefRecruitingPerm.current? gridRefRecruitingPerm.current.api:null;
  const accountManagementContractApi= gridRefAccountManagementContract.current?gridRefAccountManagementContract.current.api:null;
  const accountManagementPermApi= gridRefAccountManagementPerm.current?gridRefAccountManagementPerm.current.api:null;
  const salesContractApi= gridRefSalesContract.current?gridRefSalesContract.current.api:null;
  const salesPermApi= gridRefSalesPerm.current?gridRefSalesPerm.current.api:0;

   
    setPrinterFriendly(recruitingContractApi);
    setPrinterFriendly(recruitingPermApi);
    setPrinterFriendly(accountManagementContractApi);
    setPrinterFriendly(accountManagementPermApi);
    setPrinterFriendly(salesContractApi);
    setPrinterFriendly(salesPermApi);
    setTimeout(function () {
        window.print();
        setNormal(recruitingContractApi);
        setNormal(recruitingPermApi);
        setNormal(accountManagementContractApi);
        setNormal(accountManagementPermApi);
        setNormal(salesContractApi);
        setNormal(salesPermApi);
    }, 2000);
    
  }, []);

 return (
    <div id="myGrid" >
    <style>
    {`@media print {.no-print{display: none;}}`}
   </style>
    <TextField style={{paddingRight:'5px'}}
    id="outlined-name"
    label="Month"
    value={month}
    select
    onChange={(e) => setMonth(e.target.value)} >
    {months.map((option) => (
      <MenuItem key={option.key} value={option.value}>
        {option.key}
      </MenuItem>
    ))}
     
    </TextField>

    <TextField style={{paddingRight:'5px'}}
    id="outlined-name"
    label="Year"
    value={year}
    select
    onChange={(e) => setYear(e.target.value)} >
    {years.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.value}
      </MenuItem>
    ))}
     
    </TextField>

    <TextField style={{paddingRight:'5px'}}
    id="outlined-name"
    label="Summary"
    value={commissionPerson}
    select
    onChange={(e) => {setCommissionPerson(e.target.value);setShowSummary(false);}} >
    {commissionPersonsList.map((option) => (
      <MenuItem key={option.first_name} value={option.id}>
        {option.first_name}
      </MenuItem>
    ))}
     
    </TextField>
    
    <Button className="no-print" variant="contained" style={{padding:'15px',
}}
        onClick={()=>getCommisionDetails()}
        >
        Submit
     </Button>
     <b> All GP and Commision numbers are in USD</b>
  

     <Button className="no-print" variant="contained" style={{position:'absolute',right:'125px',padding:'15px'}}
        onClick={onBtPrint}
        >
        Print
     </Button>
     <Button className="no-print" variant="contained" style={{position:'absolute',right:'25px',padding:'15px'}}
        onClick={exportData}
        >
        Export
     </Button>
     {renderEmptySummary()}
     {recruiting_gp>0?renderSummary(recruiter_summary_contract):null}

     {renderEmptyAgGrid(onGridReady)}
     {recruiting_gp>0?renderAGGrid(gridRefRecruitingContract,contractRecruitingData,recruitingContractGridOptions,onGridReady):null}
     {recruiting_gp_perm>0?renderSummary(recruiter_summary_perm):null}

     {recruiting_gp_perm>0? renderAGGrid(gridRefRecruitingPerm,permRecruitingData,recruitingPermGridOptions,onGridReady):null}
     {recruiting_gp_perm+recruiting_gp>0?renderSummary(recruiter_summary):null}
     {account_management_gp>0?renderSummary(account_management_summary_contract):null}

     {account_management_gp>0?renderAGGrid(gridRefAccountManagementContract,contractAccountManagementData,accountManagementContractGridOptions,onGridReady):null}
     {account_management_gp_perm>0?renderSummary(account_management_summary_perm):null}

     {account_management_gp_perm>0?renderAGGrid(gridRefAccountManagementPerm,permAccountManagementData,accountManagementPermGridOptions,onGridReady):null}
     {account_management_gp_perm+account_management_gp>0?renderSummary(account_management_summary):null}


     {sales_gp>0?renderSummary(sales_summary_contract):null}
     {sales_gp>0?renderAGGrid(gridRefSalesContract,contractSalesData,salesContractGridOptions,onGridReady):null}

     {sales_gp_perm>0?renderSummary(sales_summary_perm):null}
     {sales_gp_perm>0?renderAGGrid(gridRefSalesPerm,permSalesData,salesPermGridOptions,onGridReady):null}
     {sales_gp_perm+sales_gp>0?renderSummary(sales_summary):null}
     
        {renderSummary(total_summary)}

    </div>
  
   );

};
