import React from "react";
import { EmployeeList } from "./EmployeeList";
import { EmployeeHours } from "./EmployeeHours";
import { Commissions } from "./Commisions";
import { Reports } from "./Reports";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Logout from "./Logout";
import { PermPlacements } from "./PermPlacement";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const Home = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
  //  <Box sx={{ width: '100%' }}>
      <div>
      <div style={{marginLeft:'1325px',marginTop:'5px'}}> 
      <Logout ></Logout> 
      </div>
      <span className="no-print" style={{fontSize:'24px', marginLeft:'10px'}}>SEQ Cost and Billing (Logged In as {localStorage.getItem('userName')})
      </span>
     
       <Box sx={{ borderBottom: 1, borderColor: 'divider' }}> 
        <Tabs className="no-print" value={value} onChange={handleChange} aria-label="basic tabs example">

          <Tab label="Combined Billing"  />
        <Tab label="Billing (USA)" {...a11yProps(0)} />
          <Tab label="Rates(USA)" {...a11yProps(1)} />
          <Tab label="Billing (Canada)" {...a11yProps(0)} /> 
          <Tab label="Rates(Canada)" {...a11yProps(1)} />
                
          <Tab label="Perm" {...a11yProps(2)} />
          <Tab label="Commissions"  />
        </Tabs>
       </Box> 
       <TabPanel value={value} index={0}>
        <Commissions ></Commissions>
      </TabPanel>
       <TabPanel value={value} index={1}>
        <EmployeeHours entity={'USA'}></EmployeeHours>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <EmployeeList entity={'USA'}></EmployeeList>
      </TabPanel>
      <TabPanel value={value} index={3}>
      <EmployeeHours entity={'CAN'}></EmployeeHours>
      </TabPanel>
      <TabPanel value={value} index={4}>
      <EmployeeList entity={'CAN'}></EmployeeList>
      </TabPanel>
      <TabPanel value={value} index={5}>
      <PermPlacements entity={'PERM'}></PermPlacements>
      </TabPanel>
      <TabPanel value={value} index={6}>
        <Reports ></Reports>
      </TabPanel>
     
      </div>
   // </Box>
  );
}
/*
export const Home = () => {
  return (
    <React.Fragment>
      <Tabs>
      <TabList>
      <Tab>
       Pay and Bill Rates 
      </Tab>
      <Tab>
        Commission
      </Tab>      

      </TabList>
      <TabPanel>
        <EmployeeList></EmployeeList>
      </TabPanel>
      </Tabs>
       
      
    </React.Fragment>
  );
};

*/
