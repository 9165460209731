import React, { useContext, useState, useMemo, useDispatch, Children } from 'react';
import  { Component } from 'react';

import { Link } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import JSON from 'JSON';
import axios from 'axios';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './employeeList.css';
import { currencyFormatter } from '../utils/formatter';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import { DialogContent, TextField } from '@mui/material';
import { DialogActions } from '@mui/material';

import TotalValueRenderer from './totalValueRenderer.jsx';


export const EmployeeList = (param) => {
  console.log(process.env.REACT_APP_API_URL);
  const [gridApi, setGridApi] = useState();
  const [rowData, setRowData]=useState();
  var resp, data;
  var access_token = localStorage.getItem('access_token');
  const headers = {
    "Authorization": "Bearer "+ access_token
  };

  const onGridReady = (params) => {
    
       const post_data = {
      entity: param.entity
    };
    axios.post(process.env.REACT_APP_API_URL+'/resources/',post_data,
     {headers:headers}
    ).then(response =>
    {resp = JSON.stringify(response.data);
    data = JSON.parse(resp);
    setRowData(data);
    }
    )
    .catch((error)=>{
      console.log(error);
      if(error.response.status === 401){
        localStorage.setItem('signedIn', 0);
        window.location.href = "/";
      }
    }
    
     );
    setGridApi(params.api);
  };
  
  
 
  console.log(rowData);

  
  const [columnDefs] = useState([
       { field: 'first_name',resizable: true ,headerName: 'First',sortable: true,filter:true},
       { field: 'last_name',resizable: true,headerName:'Last',sortable: true,filter:true},

      
       { field: 'client',resizable: true,headerName:'Client',sortable: true,filter:true},
       { field: 'project_name',resizable: true,headerName:'Project',sortable: true},

      
       
       { field: 'hourly_pay_rate',minWidth:90,resizable: true,headerName:'Pay(H)',
       valueFormatter: params => currencyFormatter(params.data.hourly_pay_rate),
       sortable: true,editable: true},
       { field: 'pay_ccy',resizable: true,headerName:'PayCcy',sortable: true},
       { field: 'hourly_bill_rate',minWidth:90,resizable: true,headerName: 'Bill(H)',
       valueFormatter: params => currencyFormatter(params.data.hourly_bill_rate),
       sortable: true,editable: true},
       { field: 'bill_ccy',resizable: true,headerName:'BillCcy',sortable: true},
       { field: 'w2_or_c2c',resizable: true,headerName:'Type',sortable: true},
       { field: 'vms_fee_pct',resizable: true,headerName:'VMS%',sortable: true,editable: true},
       { field: 'addl_fee_dollar',resizable: true,headerName:'$AddlFee',sortable: true,editable: true},

      
       /* { field: 'recruiter_name',resizable: true,headerName:'Recruiter',sortable: true,filter:true},
       { field: 'sourcer_name',resizable: true,headerName:'Sourcer',sortable: true, filter:true},
       { field: 'account_manager_name',resizable: true,headerName:'Acct. Mgr.',sortable: true,filter:true},
       { field: 'sales_name',resizable: true,headerName:'Sales',sortable: true,columnGroupShow:'open',filter:true}, */
       { field: 'rate_effective_date',resizable: true,headerName:'Effective',minWidth:110,sortable: true,editable: false},
       { field: 'notes',resizable: true,headerName:'Notes',sortable: true,editable: true,
       onCellValueChanged: onCellValueChanged},
       
       {
        headerName: "Action",
        field: "id",
        width: 100,
        cellRenderer: TotalValueRenderer,
        cellRendererParams: {entity:param.entity}
      }
   ]); 
   const indexColumn = {
    headerName: "#",
    field: "index",
    resizable: true,
  
    cellRenderer: (params) => {
      // Use the rowIndex property to get the index of the row
      return params.rowIndex+1;
    }
  };
   const gridOptions = {
    onCellValueChanged:onCellValueChanged,
    onGridReady:onGridReady,
    columnDefs: [indexColumn].concat(columnDefs),
    defaultColDef: {
      sortable: true,
      resizable: true,
      minWidth: 10,
      flex: 1,
    },
  };
  function onCellValueChanged(event) {
    console.log('data after changes is: ', event.data);
    var input = event.data;
    input.updated_by = localStorage.getItem('email');
    console.log(input);
    axios.post(process.env.REACT_APP_API_URL+'/update',input,{headers:headers}).then(response =>
  { 
    console.log('response from post');
    console.log(response);
    if(response.data=="Date Input Error"){
      alert("Error!! Effective Data must be after start date");
    }
  
  }
  ).catch((error)=>{
    if(error.response.status === 401){
      localStorage.setItem('signedIn', 0);
      window.location.href = "/";
    };
  });
}
 
  
function renderAGGrid(){
  
      return (
          <div style={{height: 800}} className="ag-theme-alpine">
     
      <AgGridReact
        rowData={rowData}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
      >
      </AgGridReact>
    </div>
      )
  }
  

  function exportData(){
    var yyyymmdd = new Date().toISOString().slice(0, 10);
    var filename="pay_bill_rates."+yyyymmdd+".csv";
    gridApi.exportDataAsCsv({fileName:filename});
  } 
  
 return (
  
      <div >
     &nbsp;       
     <Button  size="small" variant="contained" style={{position:'absolute',right:'25px',padding:"0px"}} 
        onClick={exportData}
     >
        Export
     </Button>
    
      {renderAGGrid()}
      </div>
 

  
   );

};

