import React from 'react';
//import { GoogleLogout } from 'react-google-login';
import './logout.css'
import Button from '@mui/material/Button';
const clientId =
  '424530879161-6k0vtkfgarnf0bf1lcopfbi7rnmclfmi.apps.googleusercontent.com';

function Logout() {
  const onSuccess = () => {
    console.log('Logout made successfully');
    localStorage.setItem('signedIn', 0);
    window.location.href=process.env.REACT_APP_HOME_URL;

    console.log('signed in = ' + localStorage.getItem('signedIn'));

  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={onSuccess}>Logout</Button>
    </div>
  ); 
}

export default Logout;