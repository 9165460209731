import React, { useInterval,useState,useEffect } from 'react';
import './login.css'
import { Helmet } from 'react-helmet';

//import { GoogleLogin } from 'react-google-login';
// refresh token
import { refreshTokenSetup } from '../utils/refreshToken';
import axios from 'axios';


const clientId ='424530879161-6k0vtkfgarnf0bf1lcopfbi7rnmclfmi.apps.googleusercontent.com'
function NewLogin(){

  function handleCredentialResponse(res) {
    console.log(res);
    console.log("Encoded JWT ID token: " + res.credential);
    var payload={'id_token':res.credential};
    console.log(process.env.REACT_APP_API_URL);
    axios.post(process.env.REACT_APP_API_URL+'/validate_token',payload).then(response =>
        { 
          console.log('response from validate_token');
          console.log(response);  
          localStorage.setItem('signedIn', 1);
         localStorage.setItem('userName','');
         localStorage.setItem('email','');
          localStorage.setItem('access_token',response.data.access_token);
          window.location.href=process.env.REACT_APP_HOME_URL;
        })
        .catch((error)=> {
            if(error){
            console.log(error);

            alert(error.response.statusText);
        }
        });
  }
  
  const [scriptLoaded, setScriptLoaded] = useState(false);

useEffect(() => {
  console.log('useEffect');
  console.log(scriptLoaded);
  if (scriptLoaded) return undefined;

  const initializeGoogle = () => {
    if (!window.google || scriptLoaded) return;

    setScriptLoaded(true);
    window.google.accounts.id.initialize({
      client_id: clientId,
      callback: handleCredentialResponse,
    });
    window.google.accounts.id.renderButton(
      document.getElementById("buttonDiv"),
      { theme: "outline", size: "medium" }  // customization attributes
    );
    window.google.accounts.id.prompt(); // also display the One Tap dialog
  };

  const script = document.createElement("script");
  script.src = "https://accounts.google.com/gsi/client";
  script.onload = initializeGoogle;
  script.async = true;
  script.id = "google-client-script";
  document.querySelector("body")?.appendChild(script);

  return () => {
    window.google?.accounts.id.cancel();
    document.getElementById("google-client-script")?.remove();
  };
}, [scriptLoaded]);



  return (
    <div>
    <Helmet>
    <script src="https://accounts.google.com/gsi/client" async defer></script>
    </Helmet> 
    <div id="buttonDiv"></div>
    </div>
  );

}
/*
function Login() {
    const onSuccess = (res) => {
    
    var payload={'id_token':res.tokenId};
    console.log(process.env.REACT_APP_API_URL);
    axios.post(process.env.REACT_APP_API_URL+'/validate_token',payload).then(response =>
        { 
          console.log('response from validate_token');
          console.log(response);
          localStorage.setItem('signedIn', 1);
          localStorage.setItem('userName',res.profileObj.name);
          localStorage.setItem('email',res.profileObj.email);
          localStorage.setItem('access_token',response.data.access_token);
          window.location.href=process.env.REACT_APP_HOME_URL;
        })
        .catch((error)=> {
            console.log(error);
            alert(error.response.statusText);
        });
  //  refreshTokenSetup(res);
  };
  const onFailure = (res) => {
    console.log('Login failed: res:', res);
    alert(
      `Failed to login.`
    );
  };
  
  return (
   
   


    <div>
      <GoogleLogin
        clientId={clientId}
        buttonText="Login"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={'single_host_origin'}
        style={{ marginTop: '100px' }}
        isSignedIn={false}
      />
    </div>
   
  );
}
*/
export default NewLogin;
//export default Login;