import React, { useState, useMemo,useContext, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import JSON from 'JSON';
import axios from 'axios';
import { FormLabel } from '@mui/material';
import { FormControl } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';


export const EditEmployee = (route) => {
  
  console.log(route);
  let id = route.match.params.id;
  let entity =route.match.params.entity;
  var access_token = localStorage.getItem('access_token');
  const headers = {
    "Authorization": "Bearer "+ access_token
  };

  function onSubmit(){
    console.log("on submit");
  }
  function handleOnChange(){
    console.log('handle on change');
  }
  var resp, data;
 
  const [rowData, setRowData]=useState();

  const onGridReady = (params) => {
    console.log('here')
    axios.get(process.env.REACT_APP_API_URL+'/details/'+entity+'/'+id,{headers:headers}).then(response =>
    {resp = JSON.stringify(response.data);
    data = JSON.parse(resp);
    setRowData(data);
    setPayCcy(data[0].pay_ccy);
    setBillCcy(data[0].bill_ccy);
    setW2C2C(data[0].w2_or_c2c);
    setVMSFeePct(data[0].vms_fee_pct);
    setAddlFeeDollar(data[0].addl_fee_dollar);
    setBillingEntity(data[0].billing_entity);
    setPayingEntity(data[0].paying_entity);
    }
    ).catch((error)=>{
      if(error.response.status === 401){
        localStorage.setItem('signedIn', 0);
        window.location.href = "/";
      }
    } );
  
  };

 

  console.log(rowData);

  const [columnDefs] = useState([
    { field: 'first_name',resizable: true ,headerName: 'First',maxWidth:180,sortable: true},
    { field: 'last_name',resizable: true,headerName:'Last',maxWidth:180,sortable: true},
    { field: 'client',resizable: true,headerName:'Client',maxWidth:180,sortable: true},
    { field: 'project_name',resizable: true,headerName:'Project',maxWidth:120,sortable: true},
    { field: 'hourly_pay_rate',resizable: true,headerName:'Pay(H)',maxWidth:180,sortable: true},
    { field: 'pay_ccy',resizable: true,headerName:'PayCcy',maxWidth: 180,sortable: true},
    { field: 'hourly_bill_rate',resizable: true,headerName: 'Bill(H)',maxWidth:180,sortable: true},
    { field: 'bill_ccy',resizable: true,headerName:'BillCcy',maxWidth:180,sortable: true},
    { field: 'w2_or_c2c',resizable: true,headerName:'W2-C2C',maxWidth:180,sortable: true},
    { field: 'vms_fee_pct',resizable: true,headerName:'VMS Fee(%)',maxWidth:180,sortable: true},
    { field: 'addl_fee_dollar',resizable: true,headerName:'Add Fee($)',maxWidth:180,sortable: true},
    { field: 'rate_effective_date',resizable: true,headerName:'Effective',maxWidth:120,sortable: true},
    { field: 'notes',resizable: true,headerName:'Notes', maxWidth:120,sortable: true} ]);

    const gridOptions = {
      onGridReady:onGridReady,
      columnDefs: columnDefs,
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        minWidth: 10,
        flex: 1,
      },
    };
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const [payRate,setPayRate]=useState(0.0);
    const [billRate,setBillRate]=useState(0.0);
    const [payCcy,setPayCcy]=useState("USD");
    const [billCcy,setBillCcy]=useState("USD");
    const [w2_or_c2c,setW2C2C]=useState("W2");
    const [vms_fee_pct,setVMSFeePct]=useState(0.0);
    const [addl_fee_dollar,setAddlFeeDollar]=useState(0.0);
    const [rate_effective_date,setEffectiveDate]=useState(0.0);
    const [paying_entity,setPayingEntity]=useState('USA');
    const [billing_entity,setBillingEntity]=useState('USA');

    function saveNewPayBillDetails()
    {
     
      
      
      var payload={'id':id,'hourly_pay_rate':payRate,'pay_ccy':payCcy,'hourly_bill_rate':billRate,'bill_ccy':billCcy,
                    'billing_entity':billing_entity,'payting_entity':paying_entity,
                   'w2_or_c2c':w2_or_c2c,'vms_fee_pct':vms_fee_pct,
                   'addl_fee_dollar':addl_fee_dollar,'rate_effective_date':rate_effective_date,'updated_by':localStorage.getItem('email')};
      const d1 = new Date(rate_effective_date);
      const d2 = new Date(rowData[0].rate_effective_date);
      if(d1 < d2) {
        alert("New rate effective date must be greater than original effetive date");
      }
      else if(payRate < 0.00 || billRate < 0.00 ){
        alert("Pay Rate and Bill Rate must be positive numbers");
      }
      else {
        axios.post(process.env.REACT_APP_API_URL+'/add_schedule',payload,{headers:headers}).then(response =>
        { 
          console.log('response from update_employees');
          console.log(response);     
          if(response.status==200){
          alert("Update Successful");

          }
          else{
            alert("Update Failed... Please try again");
          }
        }
        ).catch(error => {
          if(error.response.status === 401){
            localStorage.setItem('signedIn', 0);
            window.location.href = "/";
          }
        });  
      }
      
                   
      console.log('saving');
      console.log(payload);

    }
    const ratetypes = [
      {value:'W2'},
      {value:'C2C'}
    ];
    const currencies = [
      {
        value: 'USD',
        
      },
      {
        value: 'GBP',
        
      },
      {
        value: 'CAD',
        
      },
      {
        value: 'INR',
       
      },
    ];

  return (
    <div style={{padding:'10px'}}>  
    <h1> Bill and Pay History </h1>
      <div id="myGrid" style={gridStyle} className="ag-theme-alpine">
        
        <AgGridReact
          rowData={rowData}
          gridOptions={gridOptions}   
          onGridReady={onGridReady}
          domLayout={'autoHeight'}
 
        >
        </AgGridReact>
      </div>
      <div>
        <br></br>
        <h1> Add Modification </h1>
        <br></br>

        <TextField
        id="outlined-name"
        type="number"
        label="Pay Rate(H)"
        onChange={(e) => setPayRate(e.target.value)}
         />
         <TextField
        id="outlined-name"
        label="Pay Ccy"
        disabled={true}
        value={payCcy}>
        
        </TextField>
        {/*}
          <TextField
        id="outlined-name"
        label="Pay Ccy"
        value={payCcy}
        select
        onChange={(e) => setPayCcy(e.target.value)} >
        {currencies.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.value}
          </MenuItem>
        ))} 
         
        </TextField> */}
        <TextField
        id="outlined-name"
        label="Bill Rate(H)"
        type="number"
        onChange={(e) => setBillRate(e.target.value)}
         />
        <TextField
        id="outlined-name"
        label="Bill Ccy"
        disabled={true}
        value={billCcy}>
        
        </TextField>

         {/* <TextField
        id="outlined-name"
        label="Bill Ccy"
        value={billCcy}
        select
        onChange={(e) => setBillCcy(e.target.value)} >
        {currencies.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.value}
          </MenuItem>
        ))}
         
        </TextField> */}

        <TextField
        id="outlined-name"
        label="W2-C2C"
        disabled={true}
        value={w2_or_c2c}>

        </TextField>

        {/* <TextField
        id="outlined-name"
        label="W2-C2C"
        value={w2_or_c2c}
        select
        onChange={(e) => setW2C2C(e.target.value)} >
        {ratetypes.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.value}
          </MenuItem>
        ))}
         
        </TextField>
           */}
         
         
         <TextField
        id="outlined-name"
        type="number"
        value={vms_fee_pct}
        label="VMS Fee(%)"
        onChange={(e) => setVMSFeePct(e.target.value)}
         />

        <TextField
        id="outlined-name"
        type="number"
        value={addl_fee_dollar}
        label="Addl Fee($)"
        onChange={(e) => setAddlFeeDollar(e.target.value)}
         />

        <TextField
        id="outlined-name"
        label=""
        value={rate_effective_date}
        helperText="Rate Effective Date"
        type="date"
        onChange={(e) => setEffectiveDate(e.target.value)}
         />
        <br></br><br></br>
        <Button variant="contained"
        onClick={()=>saveNewPayBillDetails()}
        >
        Save
        </Button>
    </div>
    </div>
      
 
 
    );
};
