export function currencyFormatter(value) {
    return '$' + formatNumber(value,2);
}
  
export function formatNumber(number,pts) {
    // this puts commas into the number eg 1000 goes to 1,000,
    // i pulled this from stack overflow, i have no idea how it works
    return Number(number).toFixed(pts)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

  